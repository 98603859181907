"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "@nextui-org/react";
import gameList from "@/data/game-list.json";
import { GameData } from "@/lib/declaration";
import { Link } from "@/i18n/routing";

export default function HotGameSwiper({
  showGameCategory,
  games,
  hasSession,
}: {
  showGameCategory?: boolean;
  games: GameData[];
  hasSession?: boolean;
}) {
  const ButtonContent = ({ game }: { game: GameData }) => (
    <>
      <Image
        className="aspect-video"
        classNames={{
          wrapper: "size-full bg-center bg-cover",
          img: "object-cover object-center size-full",
        }}
        src={game.Icon}
        fallbackSrc={"/assets/img/game-placeholder.webp"}
        alt=""
        width={320}
        height={180}
      />
      <div className="text-lg font-black">
        {game.gameName.length > 24 ? game.gameName.slice(0, 24) + "..." : game.gameName}
      </div>
      <div className="inline-block">
        {(() => {
          switch (game.SubProductCode) {
            case "evolution":
              return "Evolution";
            case "netent":
              return "NetEnt";
            case "redtiger":
              return "Red Tiger";
            case "btg":
              return "BTG";
            case "nlc":
              return "NLC";
            default:
              return game.SubProductCode as string;
          }
        })()}
      </div>
      {showGameCategory && <div>{game.gameCategoryName}</div>}
    </>
  );
  return (
    <div className="h-auto w-full rounded-large bg-[#FFF5BE]">
      <Swiper className="edge-fade h-auto w-full !px-8 !py-6" slidesPerView={1} spaceBetween={10} breakpoints={{
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }} >
        {games.map((game, i) => {
          return (
            <SwiperSlide key={i}>
              {hasSession ? (
                <a
                  href={`/game/${game.gameId}`}
                  target="_blank"
                  className="relative size-full transition-all hover:scale-105"
                >
                  <ButtonContent game={game} />
                </a>
              ) : (
                <Link href={`/login`} className="relative size-full transition-all hover:scale-105">
                  <ButtonContent game={game} />
                </Link>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
