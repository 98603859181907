"use client";

import { Link } from "@/i18n/routing";
import { useLocale, useTranslations } from "next-intl";

export default function CategoryButton({ short, full }: { short?: string; full?: string }) {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <Link
      // ?category=${searchParams.category}&search=${searchParams.search}&subProductCode=${searchParams.subProductCode}&page=${searchParams.page}&count=${e.target.value}
      href={`/game?category=${short}&search=&subProductCode=All&page=1&count=20`}
      className="relative size-full transition-transform ease-soft-spring hover:scale-105"
    >
      {/* <Image src={`/assets/img/game-category/${short}.webp`} alt="" width={600} height={522} /> */}
      <svg width="100%" height="30%" viewBox="0 0 600 522" className="size-full">
        {/* image  */}
        <image
          href={`/assets/img/game-category/${short}.webp`}
          width="600"
          height="522"
          preserveAspectRatio="xMidYMid slice"
        />
        <text
          x="50%"
          y="85.5%"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize={locale === "vn" ? 45 : 65}
          fontWeight={800}
        >
          {t(short?.replace("-", "_"))}
        </text>
      </svg>
    </Link>
  );
}
