"use client";
// import Image from "next/image";
import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Banner() {
  return (
    <>
      <div className="hidden md:block">
        <Swiper className="aspect-[1440/382] h-auto w-full" loop autoplay >
          {new Array(3).fill(0).map((_, i) => (
            <SwiperSlide key={i}>
              <Image
                className="size-full min-h-full max-w-none object-cover object-bottom"
                width={1440}
                height={382}
                src={`/assets/img/banner/homepage/banner${i + 1}.webp`}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="md:hidden">
        <Swiper className="aspect-[450/250] h-auto w-full" loop autoplay>
          {new Array(3).fill(0).map((_, i) => (
            <SwiperSlide key={i}>
              <Image
                className="size-full object-contain object-center"
                width={450}
                height={250}
                src={`/assets/img/banner/homepage/mobile/banner${i + 1}.webp`}
                alt=""
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
